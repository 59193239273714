import * as React from 'react';
import { useMeasurement } from './useMeasurement';

export function useScrollReached(ref: React.RefObject<HTMLElement>) {
  const [called, setCalled] = React.useState(false);
  const [distance, setDistance] = React.useState<number | undefined>(undefined);
  // const rect = useMeasurement(ref);

  const onScroll = () => {
    const element = ref.current;
    if (element == null) return;
    // console.log('onscroll');
    const rect = element.getBoundingClientRect();

    // TODO: Browser compat
    const scrollPosition = window.scrollY;

    /** Height of the entire document */
    const documentHeight = document.body.clientHeight;

    const viewportHeight = window.innerHeight;

    /** Maximum scroll distance for the entire page */
    const maximumScroll = documentHeight - viewportHeight;

    /** Normalized (0..1) for current scrolling */
    const relativeScroll = scrollPosition / maximumScroll;

    /** Center of gravity moves further down the page the further the user
     * scrolls in px along the Y axis */
    const centerOfGravity = 0.5 * viewportHeight;

    // Now. How close is the container's center to the `centerOfGravity`?

    /** Center of the object in the Y axis. The center shifts based on the
     * current scroll position */

    const objectCenter = rect.top + rect.height * 0.5;
    /** Update distance from `centerOfGravity` to the `objectCenter`. Negative
     * when `centerOfGravity` is above the object, positive otherwise,
     * normalized to multiples of the viewport height
     *
     * [-∞]…[-1]…[ 0 ]…[1]…[+∞]
     */

    // console.log((centerOfGravity - objectCenter) / viewportHeight);

    setDistance((centerOfGravity - objectCenter) / viewportHeight);
    // setCalled(true);
  };

  React.useEffect(() => {
    // Hook up event handler
    window.addEventListener('scroll', onScroll);

    // Register cleanup function
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [ref]);

  // React.useEffect(() => {
  //   // Call once
  //   if (rect !== undefined && !called) {
  //     onScroll();
  //   }
  // });

  return distance;
}
