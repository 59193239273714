import { graphql, Link } from 'gatsby';
import * as React from 'react';

import { ContentBlock, StageBlock, TextBlock } from '../components/Blocks';
import { Insert } from '../components/Insert';
import Layout from '../components/layout';
import { Poster } from '../components/Poster';
import GatsbyImage from 'gatsby-image';
import styled from '@emotion/styled';
import { Diamond } from '../components/Diamond';

const IndexPage = ({ data }: any) => {
  const projects = data.allContentfulProjekt.edges;

  return (
    <Layout>
      <StageBlock>
        <GatsbyImage fluid={data.file.childImageSharp.fluid} />
      </StageBlock>

      <TextBlock>
        <Insert>
          <em>Wir sind gekommen,</em> um touristischen Marken und Orten Leben
          einzuhauchen, <em>sie mit Bedeutung aufzuladen.</em>
        </Insert>

        <MoreLink to="/leistungen">Erfahre wie wir das machen &gt;</MoreLink>
      </TextBlock>

      <TextBlock>
        {projects.slice(0, 2).map((edge: any) => {
          return (
            <Link key={edge.node.url} to={`/projekte/${edge.node.url}`}>
              <Poster
                title={edge.node.title}
                subtitle={edge.node.subtitle}
                description={edge.node.tags.join(', ')}
                preview={edge.node.preview.fluid}
              />
            </Link>
          );
        })}
      </TextBlock>

      <TextBlock>
        <Diamond>
          Tief schürfen finden wir schön. Es bringt das Einzigartige ans Licht.
          <br />
          So lassen wir Kampagnen, Leitbilder oder Strategien strahlen.
        </Diamond>
      </TextBlock>

      <TextBlock>
        {projects.slice(2, 4).map((edge: any) => {
          return (
            <Link key={edge.node.url} to={`/projekte/${edge.node.url}`}>
              <Poster
                title={edge.node.title}
                subtitle={edge.node.subtitle}
                description={edge.node.tags.join(', ')}
                preview={edge.node.preview.fluid}
              />
            </Link>
          );
        })}
      </TextBlock>

      <TextBlock>
        <Diamond left>
          Geschichten komponieren, die dich vom Hocker reißen, ist absolut unser
          Ding. Analog, digital, medienübergreifend – souverän den Kosmos
          beherrschend wie der Silver Surfer.
        </Diamond>
      </TextBlock>

      <TextBlock>
        {projects.slice(4, 6).map((edge: any) => {
          return (
            <Link key={edge.node.url} to={`/projekte/${edge.node.url}`}>
              <Poster
                title={edge.node.title}
                subtitle={edge.node.subtitle}
                description={edge.node.tags.join(', ')}
                preview={edge.node.preview.fluid}
              />
            </Link>
          );
        })}
      </TextBlock>

      <TextBlock>
        <Diamond>
          Erlebnis braucht Raum zur Entfaltung. Den schaffen wir und staffieren
          ihn für die reale und virtuelle Welt wundervoll aus.
        </Diamond>
      </TextBlock>
    </Layout>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "stage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allContentfulProjekt(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      edges {
        node {
          title
          subtitle
          url
          preview {
            fluid(maxWidth: 540) {
              ...GatsbyContentfulFluid
            }
          }
          tags
        }
      }
    }
  }
`;

const MoreLink = styled(Link)`
  margin-top: -2rem;
  font-size: 0.8rem;
  color: #3c434c;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export default IndexPage;
