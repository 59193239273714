import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import * as React from 'react';
import { animated, config, useSpring } from 'react-spring';

import { useScrollReached } from './useScrollReached';

interface Props {
  left: boolean;
  children: React.ReactNode;
}

const maxT = Math.PI * 2;

const rx = 300;
const ry = 150;

const point = (t: number) => {
  return `${rx * Math.cos(t)} ${ry * Math.sin(t)}`;
};

export function Diamond(props: Props) {
  const container = React.useRef<HTMLDivElement>(null);
  const approach = useScrollReached(container);

  const approachValue =
    approach !== undefined
      ? Math.min(1, Math.max(0, 1 - Math.abs(approach) / 0.5))
      : 0;

  const shape = props.left
    ? `
      M${point(maxT * (0 - 0.05 * approachValue))}
      L${point(maxT * (0.4 - 0.09 * approachValue))}
      L${point(maxT * (0.65 + 0.06 * approachValue))}
      L${point(maxT * (0.7 + 0.15 * approachValue))}
      Z
    `
    : `
      M${point(maxT * (0.1 - 0.05 * approachValue))}
      L${point(maxT * (0.3 - 0.09 * approachValue))}
      L${point(maxT * (0.6 + 0.06 * approachValue))}
      L${point(maxT * (0.86 + 0.15 * approachValue))}
      Z
    `;

  const spring: any = useSpring({
    to: {
      transform: `translateY(${40 * (1 - approachValue)}px)`,
      opacity: approachValue,
      shape,
    },
    config: config.molasses,
  });

  return (
    <Container
      ref={container}
      style={{
        height: ry * 2,
      }}
    >
      <animated.svg
        width={rx * 2}
        height={ry * 2}
        style={{
          position: 'absolute',
          right: props.left ? undefined : 0,
          left: props.left ? '-5rem' : undefined,
          top: 0,
          // opacity: value,
          // transform: `translateX(${100 * (1 - value)}%) skew(${(1 - value) *
          //   10}deg)`,
        }}
      >
        <animated.path
          transform={`translate(${rx} ${ry})`}
          d={spring.shape}
          fill="rgba(156,236,232, 0.5)"
        />
      </animated.svg>

      {/* <Box
        style={{
          opacity: value,
          transform: `translateX(${100 * (1 - value)}px)`,
        }}
      /> */}

      <Text
        left={props.left}
        style={{ opacity: spring.opacity, transform: spring.transform }}
      >
        {props.children}
      </Text>
    </Container>
  );
}

Diamond.defaultProps = {
  left: false,
};

const Container = styled.div`
  clear: both;
  position: relative;
  margin: 2em 0;
  // overflow: hidden;
`;

const Text = styled(animated.div, {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'left',
})<{ left: boolean }>`
  position: absolute;
  top: 2rem;
  right: ${props => (props.left ? undefined : 0)};
  left: ${props => (props.left ? '4rem' : undefined)};
  width: 50%;
`;
